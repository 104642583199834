import i18next from 'i18next';

import sortActionsList from '@commons/utils/sortActionsList';

export const getRowActionsSettings = ({
  handleUpdateAssociateStoreCode,
  handleUpdateAssociatedStore,
  openGenericModal,
  shouldDisplayEdi,
  handleExport,
  supplier,
  updateStoreSupplierProfileMapping,
  getAssociatedStoreCodeParams,
  isAllowedToEdit,
  shouldDisplayPlanning,
}) => {
  const rowActions = [
    {
      id: 'edit-client-code',
      actionLabel: () => i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_CLIENT_CODE'),
      handleAction: (associatedStore) => {
        const params = getAssociatedStoreCodeParams(
          associatedStore,
          handleUpdateAssociateStoreCode,
          handleUpdateAssociatedStore,
          associatedStore.customerCode,
          'customerCode',
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
      isHidden: () => !isAllowedToEdit,
    },
    {
      id: 'edit-warehouse-code',
      actionLabel: () => i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_WAREHOUSE_CODE'),
      handleAction: (associatedStore) => {
        const params = getAssociatedStoreCodeParams(
          associatedStore,
          handleUpdateAssociateStoreCode,
          handleUpdateAssociatedStore,
          associatedStore.warehouseCode,
          'warehouseCode',
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
      isHidden: () => !isAllowedToEdit,
    },
    {
      id: 'export-one',
      actionLabel: () => i18next.t('GENERAL.EXPORT_SELECTION'),
      handleAction: (item) => handleExport([item], supplier),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    ...(shouldDisplayEdi && isAllowedToEdit
      ? [
          {
            id: 'special-activate-edi',
            actionLabel: () => i18next.t('GENERAL.ACTIVATE_EDI'),
            handleAction: (item) => updateStoreSupplierProfileMapping([item], { isEdi: true }),
            actionIcon: (store) =>
              store.isEdi
                ? '/images/inpulse/power-lmgrey-small.svg'
                : '/images/inpulse/power-black-small.svg',
            isDisabled: (store) => store.isEdi,
          },
          {
            id: 'special-deactivate-edi',
            actionLabel: () => i18next.t('GENERAL.DESACTIVATE_EDI'),
            handleAction: (item) => updateStoreSupplierProfileMapping([item], { isEdi: false }),
            actionIcon: (store) =>
              !store.isEdi
                ? '/images/inpulse/power-lmgrey-small.svg'
                : '/images/inpulse/power-black-small.svg',
            isDisabled: (store) => !store.isEdi,
          },
        ]
      : []),

    ...(shouldDisplayPlanning && isAllowedToEdit
      ? [
          {
            id: 'special-activate-planning',
            actionLabel: () => i18next.t('ADMIN.SUPPLIERS.ACTIVATE_PLANNING'),
            handleAction: (item) =>
              updateStoreSupplierProfileMapping([item], { hasPlanning: true }),
            actionIcon: (item) =>
              item.hasPlanning
                ? '/images/inpulse/power-lmgrey-small.svg'
                : '/images/inpulse/power-black-small.svg',
            isDisabled: (item) => item.hasPlanning,
          },
          {
            id: 'special-deactivate-planning',
            actionLabel: () => i18next.t('ADMIN.SUPPLIERS.DEACTIVATE_PLANNING'),
            handleAction: (item) =>
              updateStoreSupplierProfileMapping([item], { hasPlanning: false }),
            actionIcon: (item) =>
              !item.hasPlanning
                ? '/images/inpulse/power-lmgrey-small.svg'
                : '/images/inpulse/power-black-small.svg',
            isDisabled: (item) => !item.hasPlanning,
          },
        ]
      : []),
  ];

  return sortActionsList(rowActions);
};

export const getActionsSettings = ({
  selectedItems,
  handleUpdateAssociateStoreCode,
  handleUpdateAssociatedStore,
  openGenericModal,
  shouldDisplayEdi,
  handleExport,
  associatedStores,
  supplier,
  updateStoreSupplierProfileMapping,
  getAssociatedStoreCodeParams,
  isAllowedToEdit,
  shouldDisplayPlanning,
}) => {
  const hasSelectedItems = !!selectedItems.length;
  const oneObjectSelected = selectedItems.length === 1;
  const actions = [
    {
      id: 'edit-client-code',
      actionLabel: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_CLIENT_CODE'),
      handleAction: (associatedStore) => {
        const params = getAssociatedStoreCodeParams(
          associatedStore,
          handleUpdateAssociateStoreCode,
          handleUpdateAssociatedStore,
          associatedStore.customerCode ? associatedStore.customerCode.trim() : '',
          'customerCode',
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
      isHidden: () => !oneObjectSelected || !isAllowedToEdit,
    },
    {
      id: 'edit-warehouse-code',
      actionLabel: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_WAREHOUSE_CODE'),
      handleAction: (associatedStore) => {
        const params = getAssociatedStoreCodeParams(
          associatedStore,
          handleUpdateAssociateStoreCode,
          handleUpdateAssociatedStore,
          associatedStore.warehouseCode ? associatedStore.warehouseCode.trim() : '',
          'warehouseCode',
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
      isHidden: () => !oneObjectSelected || !isAllowedToEdit,
    },
    {
      id: 'export',
      actionLabel: selectedItems.length
        ? i18next.t('GENERAL.EXPORT_SELECTION')
        : i18next.t('GENERAL.EXPORT_LIST_XLS'),
      handleAction: () =>
        selectedItems.length
          ? handleExport(selectedItems, supplier)
          : handleExport(associatedStores, supplier),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  const ediActions =
    shouldDisplayEdi && isAllowedToEdit && hasSelectedItems
      ? _addEdiActions({ selectedItems, updateStoreSupplierProfileMapping })
      : [];

  const planningActions =
    shouldDisplayPlanning && isAllowedToEdit && hasSelectedItems
      ? _addPlanningActions({ selectedItems, updateStoreSupplierProfileMapping })
      : [];

  return sortActionsList([...actions, ...ediActions, ...planningActions]);
};

const _addEdiActions = ({ selectedItems, updateStoreSupplierProfileMapping }) => {
  const onlyOneItemSelected = selectedItems.length === 1;

  return [
    {
      id: 'special-activate-edi',
      actionLabel: i18next.t('GENERAL.ACTIVATE_EDI'),
      handleAction: () => updateStoreSupplierProfileMapping(selectedItems, { isEdi: true }),
      actionIcon: () =>
        onlyOneItemSelected && selectedItems[0].isEdi
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => onlyOneItemSelected && selectedItems[0].isEdi,
    },
    {
      id: 'special-deactivate-edi',
      actionLabel: i18next.t('GENERAL.DESACTIVATE_EDI'),
      handleAction: () => updateStoreSupplierProfileMapping(selectedItems, { isEdi: false }),
      actionIcon: () =>
        onlyOneItemSelected && !selectedItems[0].isEdi
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => onlyOneItemSelected && !selectedItems[0].isEdi,
    },
  ];
};

const _addPlanningActions = ({ selectedItems, updateStoreSupplierProfileMapping }) => {
  const onlyOneItemSelected = selectedItems.length === 1;

  return [
    {
      id: 'special-activate-planning',
      actionLabel: i18next.t('ADMIN.SUPPLIERS.ACTIVATE_PLANNING'),
      handleAction: () => updateStoreSupplierProfileMapping(selectedItems, { hasPlanning: true }),
      actionIcon: () =>
        onlyOneItemSelected && selectedItems[0].hasPlanning
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => onlyOneItemSelected && selectedItems[0].hasPlanning,
    },
    {
      id: 'special-deactivate-planning',
      actionLabel: i18next.t('ADMIN.SUPPLIERS.DEACTIVATE_PLANNING'),
      handleAction: () => updateStoreSupplierProfileMapping(selectedItems, { hasPlanning: false }),
      actionIcon: () =>
        onlyOneItemSelected && !selectedItems[0].hasPlanning
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => onlyOneItemSelected && !selectedItems[0].hasPlanning,
    },
  ];
};

export default {
  getActionsSettings,
  getRowActionsSettings,
};
