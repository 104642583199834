import 'moment/locale/fr';
import { connect } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openMediumModal } from '@actions/modal';
import { patchSupplier, patchSupplierError, patchSupplierSuccess } from '@actions/supplier';

import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { userHasRoleInpulse } from '@commons/utils/roles';
import Footer from '@commons/Footer/Footer';
import Text from '@commons/Text';
import WhiteCardForm from '@commons/WhiteCardForm';

import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { storeSupplierProfileMapping } from '@services/storeSupplierProfileMapping';
import { supplierProfile as supplierProfilesService } from '@services/supplierProfile';
import catalogsService from '@services/catalogs';
import clientService from '@services/client';

import HeaderPicture from '@admin/components/HeaderPicture';
import OrderParams from '@admin/components/OrderParams';

import '../../Suppliers.css';
import { ButtonsContainer } from '../../components/SupplierDetail/styledComponents';
import { handleSave, isFormValid } from '../../components/SupplierDetailSave';

import { canEditSupplierFromDetailView } from '@selectors/actions/supplierActions';
import {
  Container,
  FormContainer,
  PictureContainer,
  DropdownItem,
  DropdownIcon,
} from './styledComponents';
import {
  SUPPLIER_FORM_INPUTS,
  SUPPLIER_INFORMATIONS_INPUTS,
  SUPPLIER_LOCATION_INPUTS,
} from './utils/formInputsConfiguration';

moment.locale('fr');

const DEFAULT_SUPPLIER_PICTURE = '/images/inpulse/supplier-kitchen-photo-placeholder.svg';

const SupplierDetail = (props) => {
  const {
    handlePrevPage,
    handleSetOpenModalAddNewItemDropdown,
    handleSupplierChange,
    showErrorMessage,
    showMessage,
    supplier: formattedSupplier,
    supplierId,
    suppliersCategories,
    picture,
    setPicture,
    setSelectedPictureFile,
    // redux props
    user,
    client,
    pageLoaded,
    pageLoading,
    patchSupplier,
    authorizedActions,
  } = props;

  // Global states
  const [supplier, setSupplier] = useState({
    name: '',
    category: '',
    code: '',
    adress: '',
    city: '',
    postCode: '',
    country: '',
    active: true,
    picture: '',
    enableMultipleOrderOnSameDate: false,
    displayUnavailableProductOrders: true,
    includeExcelInOrderMail: false,
    isKitchen: false,
    catalogId: null,
    languageId: null,
  });
  const [hasSSPMWithEdi, setHasSSPMWithEdi] = useState(false);
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);

  const [multipleOrder, setMultipleOrder] = useState(false);
  const [displayUnavailableProductOrders, setDisplayUnavailableProductOrders] = useState(true);
  const [xlsWithOrder, setXlsWithOrder] = useState(false);

  const [catalogs, setCatalogs] = useState([]);
  const [languagesDropdownItems, setLanguagesDropdownItems] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});

  const [areInputsDisabled, setAreInputsDisabled] = useState(supplier.isKitchen);
  // Form
  const [, setForm] = useState(useForm());

  const allInputs = SUPPLIER_FORM_INPUTS({
    categories: suppliersCategories,
    isKitchen: supplier.isKitchen,
    hasMultipleCurrencies: client.hasMultipleCurrencies,
    areInputsDisabled,
  });

  const supplierForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: supplierForm.control,
  });

  const checkSSPMWithEdi = async (supplierId) => {
    const storeSupplierProfileMappings =
      await storeSupplierProfileMapping.getStoreSupplierProfileMappingsOfSupplier(supplierId);

    const hasSSPMWithEdi = storeSupplierProfileMappings.some(({ isEdi }) => isEdi);

    setHasSSPMWithEdi(hasSSPMWithEdi);
  };

  const fetchLanguages = async () => {
    try {
      const languages = await clientService.getAllLanguages();

      const formattedLanguages = languages.map((language) => ({
        ...language,
        iconSrc: `/images/flags/icon-flag-${language.code}.svg`,
        renderValue: () => (
          <DropdownItem>
            <DropdownIcon src={`/images/flags/icon-flag-${language.code}.svg`} />
            <Text>{language.name}</Text>
          </DropdownItem>
        ),
      }));

      setLanguagesDropdownItems(formattedLanguages);
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'));
    }
  };

  const reloadData = async () => {
    pageLoading();

    try {
      if (client.hasLocalCatalogs) {
        const fetchedCatalogs = await catalogsService.getCatalogsByClientId();

        setCatalogs(fetchedCatalogs);
      }

      await fetchLanguages();

      const result = await supplierProfilesService.getSupplierProfilesOfSupplier(supplierId);
      await checkSSPMWithEdi(supplierId);

      const fetchedSupplier = result.supplier;

      setSupplier(fetchedSupplier);

      setAreInputsDisabled(
        !canEditSupplierFromDetailView(authorizedActions) ||
          !canUpdateAssociatedSupplier(user, fetchedSupplier, client),
      );
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.GET_SUPPLIER_PROFILE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    if (!!picture) {
      formFields.picture = picture;
    }

    formFields.enableMultipleOrderOnSameDate = multipleOrder;
    formFields.displayUnavailableProductOrders = displayUnavailableProductOrders;
    formFields.includeExcelInOrderMail = xlsWithOrder;

    const currentCatalog = supplierForm.getValues('catalog');

    if (currentCatalog) {
      formFields.catalogId = currentCatalog.id;
    }

    const currentLanguage = supplierForm.getValues('language');

    if (currentLanguage) {
      setSelectedLanguage(currentLanguage);
      formFields.languageId = currentLanguage.id;
    }

    handleSupplierChange(formFields);

    const isFormDirty = get(supplierForm, 'formState.isDirty', false);

    const dirtyFields = Object.keys(get(supplierForm, 'formState.dirtyFields', {}));

    const currentCategory = supplierForm.getValues('category');
    const supplierOriginalCategory = supplier.category || '';

    const supplierOriginalCatalogId = supplier.catalogId || '';

    const hasAtLeastOneDifferentField = dirtyFields.some((field) => {
      if (field === 'catalog') {
        return !!currentCatalog && currentCatalog.id !== supplierOriginalCatalogId;
      }

      if (field === 'category') {
        return (
          !!currentCategory &&
          currentCategory.name.toLowerCase() !== supplierOriginalCategory.toLowerCase()
        );
      }

      return !isEqual(supplier[field], formFields[field]);
    });

    if (
      (isFormDirty && hasAtLeastOneDifferentField) ||
      picture !== supplier.picture ||
      supplier.enableMultipleOrderOnSameDate !== multipleOrder ||
      supplier.displayUnavailableProductOrders !== displayUnavailableProductOrders ||
      supplier.includeExcelInOrderMail !== xlsWithOrder ||
      // We need this check because sometimes the dirtyFields do not contain the category
      (!!currentCategory &&
        (currentCategory.name || '').toLowerCase() !== supplierOriginalCategory.toLowerCase())
    ) {
      setIsFooterDisplayed(true);
      return;
    }

    setIsFooterDisplayed(false);
  }, [formFields, picture, multipleOrder, displayUnavailableProductOrders, xlsWithOrder, supplier]);

  useEffect(() => {
    const currentCategory = supplierForm.getValues('category');

    if (!currentCategory || !formattedSupplier.category) {
      return;
    }

    let supplierCategory = '';

    if (suppliersCategories.length) {
      supplierCategory = suppliersCategories.find(({ name }) => {
        if (!name) {
          return false;
        }

        return name.toLowerCase() === formattedSupplier.category.toLowerCase();
      });
    }

    if (
      (!currentCategory && !!formattedSupplier.category) ||
      currentCategory.name !== formattedSupplier.category
    ) {
      supplierForm.setValue('category', supplierCategory);
    }
  }, [formattedSupplier]);

  useEffect(() => {
    if (isEmpty(supplier)) {
      return;
    }

    (async function loadData() {
      setPicture(supplier.picture);

      // Informations

      if (supplier.isKitchen) {
        supplierForm.setValue('category', i18next.t('GENERAL.CENTRAL'));
      } else {
        const category = !!supplier.category ? supplier.category.toLowerCase() : null;

        const supplierCategory = suppliersCategories.find(
          ({ name }) => name.toLowerCase() === category,
        );

        supplierForm.setValue('category', supplierCategory);
      }

      supplierForm.setValue('name', supplier.name);
      supplierForm.setValue('code', supplier.code);
      supplierForm.setValue('siren', supplier.siren);
      supplierForm.setValue('currency', supplier.currency);

      if (client.hasLocalCatalogs) {
        const initialCatalog = catalogs.find(({ id }) => id === supplier.catalogId);

        supplierForm.setValue('catalog', initialCatalog);
      }

      const initialLanguage = languagesDropdownItems.find(({ id }) => id === supplier.languageId);

      supplierForm.setValue('language', initialLanguage);

      // Location
      supplierForm.setValue('adress', supplier.adress);
      supplierForm.setValue('postCode', supplier.postCode);
      supplierForm.setValue('city', supplier.city);
      supplierForm.setValue('country', supplier.country);

      // Order params
      setMultipleOrder(supplier.enableMultipleOrderOnSameDate);
      setDisplayUnavailableProductOrders(supplier.displayUnavailableProductOrders);

      if (!hasSSPMWithEdi) {
        setXlsWithOrder(supplier.includeExcelInOrderMail);
      }

      setForm(supplierForm);
    })();
  }, [supplier]);

  const isAllowedToUpdate =
    canEditSupplierFromDetailView(authorizedActions) &&
    canUpdateAssociatedSupplier(user, supplier, client);
  return (
    <Container>
      <FormContainer>
        <PictureContainer>
          <HeaderPicture
            isDefaultThumbnail={!supplier.picture}
            picture={picture || DEFAULT_SUPPLIER_PICTURE}
            readOnly={areInputsDisabled}
            setSelectedFile={setSelectedPictureFile}
            subTitle={
              supplier.isKitchen && i18next.t('ADMIN.SUPPLIERS.KITCHEN_SUPPLIER_DETAIL_SUBTITLE')
            }
            subTitleIcon={supplier.isKitchen ? '/images/inpulse/central-black-small.svg' : null}
            title={supplier.name}
            onPictureChange={setPicture}
          />
          <OrderParams
            canIncludeExcelInOrderMail={!hasSSPMWithEdi}
            displayUnavailableProductOrders={displayUnavailableProductOrders}
            isAllowedToUpdate={isAllowedToUpdate}
            multipleOrder={multipleOrder}
            setDisplayUnavailableProductOrders={setDisplayUnavailableProductOrders}
            setMultipleOrder={setMultipleOrder}
            setXlsWithOrder={setXlsWithOrder}
            xlsWithOrder={xlsWithOrder}
          />
        </PictureContainer>
        <WhiteCardForm
          form={supplierForm}
          inputs={SUPPLIER_INFORMATIONS_INPUTS({
            userHasRoleInpulse: userHasRoleInpulse(user),
            categories: suppliersCategories,
            handleCategoryCreation: handleSetOpenModalAddNewItemDropdown,
            hasMultipleCurrencies: client.hasMultipleCurrencies,
            hasLocalCatalogs: client.hasLocalCatalogs,
            catalogs,
            isKitchen: supplier.isKitchen,
            areInputsDisabled,
          })}
          shouldDisplayError={false}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_GENERAL_INFORMATION')}
        />
        <WhiteCardForm
          form={supplierForm}
          inputs={SUPPLIER_LOCATION_INPUTS({
            areInputsDisabled,
            isKitchen: supplier.isKitchen,
            languagesDropdownItems,
            selectedLanguage,
          })}
          shouldDisplayError={false}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_LOCATION')}
        />
      </FormContainer>
      {!areInputsDisabled && isFooterDisplayed && (
        <Footer>
          <ButtonsContainer>
            <Button
              color={'inpulse-outline'}
              handleClick={handlePrevPage}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={() =>
                handleSave({
                  handlePrevPage: handlePrevPage,
                  pageLoaded: pageLoaded,
                  pageLoading: pageLoading,
                  patchSupplier: patchSupplier,
                  showMessage: showMessage,
                  supplier: formattedSupplier,
                  supplierId: supplierId,
                  client: client,
                  picture: picture,
                })
              }
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={!isFormValid(formattedSupplier)}
              label={i18next.t('GENERAL.SAVE')}
            />
          </ButtonsContainer>
        </Footer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  patchSupplier: (supplier) =>
    dispatch(patchSupplier(supplier)).then(
      (result) => dispatch(patchSupplierSuccess(result)),
      (error) => {
        dispatch(patchSupplierError(error));
        throw 'Error';
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetail);
